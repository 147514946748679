import { ONE_DAY_IN_MS } from '@/utils/constant';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isShopifyDomain']),
  },
  methods: {
    isInactiveDomain(domain) {
      const { domain: domainName, lastRequestDate } = domain;
      return (
        !this.isShopifyDomain(domainName) &&
        (!lastRequestDate ||
          new Date(lastRequestDate).getTime() < new Date().getTime() - ONE_DAY_IN_MS)
      );
    },
  },
};
